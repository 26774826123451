<template>
  <div ref='map' style="width:100%; height:680px"></div>
</template>
 
<script>
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import 'proj4'
import 'proj4leaflet'
import 'leaflet.chinatmsproviders'
 
export default {
  data() {
    return {
    }
  },
  mounted() {
    var normalMap = L.tileLayer.chinaProvider('Baidu.Normal.Map', {
      maxZoom: 18,
      minZoom: 8
    });

    var map = L.map(this.$refs.map, {
      crs: L.CRS.Baidu,
      layers: [normalMap],
      center: [34.697487, 135.531584],
      zoom: 16
    });
  }
}
</script>
